// Settings

// Dynamic CSS vars used at runtime
:root {
  // fonts
  --fontfamily-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --fontfamily-mono: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  --fontfamily-heading: "hattonultrabold", var(--fontfamily-sans);
  --fontfamily-tagline: "maison_monolight", var(--fontfamily-sans);
  --fontfamily-bodycopy: "neue_machinalight", var(--fontfamily-sans);
  --fontfamily-ui: "maison_monolight", var(--fontfamily-sans);
  
  // colour palette (source XD)
  // home #F7E9E9
  // productions #EFEAF0
  // calendar #F0EBEA
  // manifest #E9F5F0
  // waterwende #F6F7E9
  
  // colours
  --color-black: #231f20;
  --color-white: #fff;
  --color-brand-1: #f7e9e9;
  --color-brand-2: #efeaf0;
  --color-brand-3: #f0ebea;
  --color-brand-4: #e9f5f0;
  --color-brand-5: #f6f7e9;
  --color-brand-1-dark: #331212;
  --color-brand-2-dark: #312433;
  --color-brand-3-dark: #332624;
  --color-brand-4-dark: #162a25;
  --color-brand-5-dark: #303313;
  --color-text: var(--color-black);
  --color-background: var(--color-white);
  
  // typography & vertical rythm
  --baseline: 1.45;
  --content-width: 60rem;
  --fontsize-base: 1rem;
  --fontsize-bodycopy: 1.125rem;
  --fontsize-small: 0.875rem;
  --fontsize-large: 1.25rem;
  
  // whitespace
  --gutter: 3rem;
  --whitespace: 1.5rem;
}

// Static variables for calculations in sass 
$fontpath: '../fonts/webfontkit/';