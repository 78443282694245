// header
// consists of the branding bar, menu and language toggle

.topbar {
  color: var(--color-white);
  display: flex;
  margin-top: 20px;
  position: sticky;
  top: 0;
  transition: all 0.5s;
  z-index: 10;

  &.is-sticky {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &::before,
  &::after {
    background: white;
    content: '';
    flex-grow: 1;
    height: 4px;
    position: relative;
    top: 28px;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
}

.navbar {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 60px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--content-width);
  width: 100%;
}

.logo {
  background: url(../images/logo-silbersee-white.svg) no-repeat 0px center;
  background-size: 200px 24px;
  display: block;
  height: 60px;
  width: 200px;
}

.spacer {
  background-image: linear-gradient(transparent 28px, white 28px, white 32px, transparent 32px);
  flex-grow: 1;
  height: 60px;
}

// the menu hamburger
.menutoggler {
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 1.25rem;
  height: 24px;
  line-height: 1;
  margin: 0 1rem;
  padding: 0.25rem 0.75rem;
  width: 32px;
}

.icon-bar {
  background-color: rgba(255, 255, 255, 0);
  height: 3px;
  position: absolute;
  transform: translate(-1rem, -2px);
  transition: all 0ms 240ms;
  width: 32px;

  &::before,
  &::after {
    background-color: #fff;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 32px;
  }

  &::before {
    bottom: 0;
    transition-property: bottom;
    transform: rotate(-45deg);
    transition: 240ms cubic-bezier(0.35, 1, 0.75, 1), transform 240ms 240ms cubic-bezier(0.35, 1, 0.75, 1);
  }

  &::after {
    top: 0;
    transition-property: top;
    transform: rotate(45deg);
    transition: 240ms cubic-bezier(0.35, 1, 0.75, 1), transform 240ms 240ms cubic-bezier(0.35, 1, 0.75, 1);
  }
  
  .collapsed & {
    background-color: #fff;
    
    &::before,
    &::after {
      transform: rotate(0deg);
      transition: 240ms 240ms cubic-bezier(0.35, 1, 0.75, 1), transform 240ms cubic-bezier(0.35, 1, 0.75, 1);
    }

    &::before {
      bottom: 9px;
    }

    &::after {
      top: 9px;
    }
  }
}

.locale {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  margin-right: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.mainmenu {
  margin: 0 auto;
  max-width: var(--content-width);
  position: relative;
  top: 60px;
  
  
  nav {
    position: relative;
  }
  
  ul {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    min-width: 300px;
    overflow: hidden;
    list-style: none;
    padding: 0.5rem 1rem 1rem 1rem;
    position: absolute;
    right: 0;
    transition-duration: .35s;
    transition-property: max-height, padding;
    transition-timing-function: ease-in-out;
  }

  &.hidden ul {
    max-height: 0;
    padding: 0 1rem;
  }

  a {
    display: block;
    line-height: 3rem;

    &:hover,
    &:active {
      color: var(--color-brand-1);
    }
  }
}