// forms
input {
  // background-color: var(--color-white);
  border: 0;
  padding: 0.5rem;
}

// MailChimp embedded signup form
[name='mc-embedded-subscribe-form'] {
  display: grid;
  gap: calc(var(--whitespace) / 2);
  
  div {
    align-items: center;
    display: grid;
    gap: calc(var(--whitespace) / 2);
    
    &:not(:last-of-type) {
      grid-template-columns: 1fr 1fr;
    }
  }

  label[for*='EMAIL']::after {
    content: '*'
  }

  input[type='submit'] {
    justify-self: center;
  }
}

// MailChimp modal signup form
.signup {
  align-self: center;
}

.modal {
  background-color: rgba(0,0,0,.85);
  display: none;
  inset: 0;
  position: fixed;
  text-align: center;
  transition: all .25s ease-in-out;
  
  &.is-open {
    display: flex;
    z-index: 100;
  }
  
  &-content {
    --color-background: var(--color-brand-2);

    background-color: var(--color-background);
    color: var(--color-text);
    margin: auto;
    max-width: calc(var(--content-width) / 2);
    padding: var(--gutter) var(--whitespace);
    position: relative;
    width: 100%;
  }

  .close {
    background-color: transparent;
    border: 0;
    color: var(--color-text);
    cursor: pointer;
    font-family: var(--fontfamily-bodycopy);
    font-size: 2rem;
    height: 3rem;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .25s ease-in-out;
    width: 3rem;

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transform: scale(0.98);
    }
  }

  input:not(.btn) {
    --color-background: var(--color-white);

    background-color: var(--color-background);
    color: var(--color-text);
  }

  [name='mc-embedded-subscribe-form']  {
    div {
      grid-template-columns: 1fr;
    }
  }
}

body.modal-active {
  overflow: hidden;
}