// Layout
@use 'settings';

// sections are full-width, but their content has a max-width
// this includes articles as well
.content {
  margin: 0 auto;
  max-width: var(--content-width);
  padding: var(--gutter) var(--whitespace);
  width: 100%;

  // content on booklet
  .booklet & {
    --gutter: var(--whitespace);
  }

  // we override the default whitespace on the section headings
  section.booklet & {
    h2 {
      margin-bottom: 0;
    }
  }

  // booklet swicth
  &.booklet {
    --gutter: 0;
    text-align: right;
  }
}

article > header,
footer > .colophon {
  @extend .content;
}

main {
  padding: var(--gutter) 0;

  // each content element has some whitespace relative to the baseline
  h2, h3, figure, p, ol, ul {
    margin-bottom: calc(var(--baseline) * 1rem);
  }
  
  // as Prosemirror defaults to paragraphs with a listitem we're overriding styling here
  li p {
    margin: 0;
  }
}

footer {
  p {
    margin-bottom: calc(var(--baseline) * 1rem);
  }
}

// the content is served in one to three columns using layout.php
.grid {
  @extend .content;
  --columns: 12;
  --span: var(--columns);
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;

  // grid on booklet
  .booklet .content + &,
  .booklet .grid + & {
    padding: 0 var(--whitespace);
  }
}

.grid > .column {
  display: flex;
  flex-direction: column;
  // gap: calc(var(--baseline) * 1rem);
  // margin-bottom: var(--gutter);
}

@media screen and (min-width: 50rem) { // 800px
  .grid {
    grid-template-columns: repeat(var(--columns), 1fr);
  }

  .grid > .column {
    grid-column: span var(--span);
  }
}

.colophon {
  text-align: center;
}

// image aspect ratios
[data-ratio*='auto'] {
  aspect-ratio: auto;
}
[data-ratio*='1/1'] {
  aspect-ratio: 1/1;
}
[data-ratio*='16/9'] {
  aspect-ratio: 16/9;
}
[data-ratio*='10/8'] {
  aspect-ratio: 10/8;
}
[data-ratio*='21/9'] {
  aspect-ratio: 21/9;
}
[data-ratio*='7/5'] {
  aspect-ratio: 7/5;
}
[data-ratio*='4/3'] {
  aspect-ratio: 4/3;
}
[data-ratio*='5/3'] {
  aspect-ratio: 5/3;
}
[data-ratio*='3/2'] {
  aspect-ratio: 3/2;
}
[data-ratio*='3/1'] {
  aspect-ratio: 3/1;
}
[data-ratio*='21.51/9'] {
  aspect-ratio: 21.51/9;
}