// Fonts
@use 'settings';

@font-face {
    font-family: 'maison_monobold';
    src: url('#{settings.$fontpath}maisonmono-bold-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}maisonmono-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'maison_monodemi';
    src: url('#{settings.$fontpath}maisonmono-demi-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}maisonmono-demi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'maison_monolight';
    src: url('#{settings.$fontpath}maisonmono-light-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}maisonmono-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'maison_monomedium';
    src: url('#{settings.$fontpath}maisonmono-medium-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}maisonmono-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'maison_monoregular';
    src: url('#{settings.$fontpath}maisonmono-regular-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}maisonmono-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_machinablack';
    src: url('#{settings.$fontpath}neuemachina-black-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}neuemachina-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neue_machinalight';
    src: url('#{settings.$fontpath}neuemachina-light-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}neuemachina-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hattonlight';
    src: url('#{settings.$fontpath}hatton-light-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}hatton-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hattonmedium';
    src: url('#{settings.$fontpath}hatton-medium-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}hatton-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hattonultrabold';
    src: url('#{settings.$fontpath}hatton-ultrabold-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}hatton-ultrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hattonregular';
    src: url('#{settings.$fontpath}hatton-variable-webfont.woff2') format('woff2'),
         url('#{settings.$fontpath}hatton-variable-webfont.woff') format('woff');
    font-weight: 100 1000;
    font-style: normal;

}