// cover
// consists of the coverimage or carousel on each page

.cover {
  background-color: var(--color-black);
  background-size: cover;
  color: var(--color-white);
  display: grid;
  height: 80vh;
  // height: calc( var(--vh, 1vh) * 80);
  margin-top: -80px;
  overflow: hidden;
  position: relative;

  &:not(.parallax) {
    height: unset;
    min-height: 40vh;
  }

  // simpleParallax settings puts scale on image
  // so to prevent jump in scale we scale image initially the same amount
  .parallax {
    img {
      transform: translate3d(0px, -18px, 0px) scale(1.3);
    }
  }

  // SimpleParallax element
  .simpleParallax {
    height: 100%;
    // opacity: 0;
    position: absolute;
    transition: all .5s ease-in-out;
    width: 100%;
    z-index: 1;

    // &.simple-parallax-initialized {
    //   opacity: 1;
    // }
  }
  
  .content {
    align-content: end;
    display: grid;
    grid-template-columns: 1fr;
    margin: calc(var(--gutter) * 2) auto var(--gutter);
    max-width: var(--content-width);
    padding: 1rem;
    text-align: right;
    z-index: 2;
    // https://blog.logrocket.com/understanding-min-content-max-content-fit-content-css/
    // -moz-width: fit-content;
    // width: fit-content;
  }

  img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

// additional styling on the carousel using Swiper.js
.swiper {
  --swiper-pagination-bottom: 0;

  background-color: var(--color-black);
  height: 80vh;
  margin-top: -80px;
  
  &-slide {
    --swiper-pagination-bullet-width: 10px;
    align-items: flex-end;
    display: flex !important;
  }

  img {
    height: 100%;
    object-fit: cover;
    opacity: 0;
    position: absolute;
    transition: 1s ease-in-out;
    width: 100%;
    z-index: -1;
  }

  .slide-info {
    color: var(--color-white);
    flex: 1 1 auto;
    margin: 0 auto 10vh;
    max-width: var(--content-width);
    opacity: 0;
    padding: 1rem;
    text-align: right;
    text-decoration: none;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

  &-initialized {
    img, .slide-info {
      opacity: 100%;
    }
  }
  
  // pagination
  &-pagination {
    max-width: 100%;
    padding: 5vh 0;
  }

  &-pagination-bullet {
    background: var(--color-white) !important;
    height: 10px !important;
    opacity: .5 !important;
    width: 10px !important;
  }

  &-pagination-bullet-active {
    opacity: 1 !important;
  }
}