// Blocktypes

.blocktype {
  display: inline-block;

  &-accordion {
    margin: 0 auto calc(var(--baseline) * 1rem);
    max-width: calc(960px / 12 * 8);

    .booklet & {
      // margin: 0 auto;
      max-width: calc(960px / 12 * 10);
    }

    .panel {
      font-size: var(--fontsize-base);
      // margin: 0 auto;
      // max-width: calc(960px/12*8);
    }

    // accordions
    // option 1: using checkbox
    .accordion {
      overflow: hidden;

      input {
        opacity: 0;
        position: absolute;
        z-index: -1;

        &:focus {
          + label {
            background-color: var(--color-black);
            color: var(--color-white);
            // outline: 4px solid orange;
            // outline-offset: 5px;
            text-decoration: none;
            transition: all 0.25s ease-in-out;
          }
        }
      }

      label {
        cursor: pointer;
        display: block;
        font-family: var(--fontfamily-heading);
        line-height: 2rem;
        padding: calc(var(--whitespace) / 2);
        text-transform: uppercase;
        transition: all 0.5s ease-in-out;

        &::before {
          content: '+';
          font-family: var(--fontfamily-mono);
          font-size: 1.5rem;
          line-height: 2rem;
          margin-right: 0.5rem;
        }

        &:hover,
        &:focus {
          background-color: var(--color-black);
          color: var(--color-white);
          text-decoration: none;
          transition: all 0.25s ease-in-out;
        }
      }

      .panel {
        max-height: 0;
        opacity: 0;
        padding: 0 calc(var(--whitespace) / 2);
        transition-duration: 0.25s;
        transition-property: max-height, opacity, padding;
        transition-timing-function: ease-in-out;
      }

      h3 {
        padding-top: calc(var(--baseline) * 1rem);
      }

      input:checked {
        ~ label {
          &::before {
            content: '-';
          }
        }

        ~ .panel {
          max-height: 100%;
          opacity: 1;
          padding: calc(var(--whitespace) / 2);
        }
      }
    }

    // option 2: using details & summary (::marker not styleable in iOS)
    .accordion-2 {
      summary {
        cursor: pointer;
        font-family: var(--fontfamily-heading);
        padding: calc(var(--whitespace) / 2);
        text-transform: uppercase;
        transition: all 0.5s ease-in-out;

        &::-webkit-details-marker,
        &::marker {
          content: '+ ';
          font-family: var(-mono);
          font-size: 1.6rem;
        }

        &:hover {
          background-color: var(--color-black);
          color: var(--color-white);
          text-decoration: none;
          transition: all 0.25s ease-in-out;
        }
      }

      p {
        max-height: 0;
        padding: calc(var(--whitespace) / 2);
        transition-duration: 0.25s;
        transition-property: max-height, padding;
        transition-timing-function: ease-in-out;
      }

      &[open] {
        padding-bottom: var(--whitespace);

        summary {
          &::-webkit-details-marker,
          &::marker {
            content: '- ';
          }
        }
      }
    }

    .accordion-3 {
      .panel {
        max-height: 0;
      }

      .panel:not([aria-hidden='true']) {
        max-height: 100vh;
      }
    }
  }

  &-button {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(var(--baseline) * 1rem);
  }

  &-calendar-preview {
    .playdates .playdate-info {
      text-align: center;
      width: 100%;
    }
  }

  &-gallery {
    ul {
      display: grid;
      gap: var(--whitespace);
      grid-template-columns: repeat(
        auto-fill,
        minmax(215px, 1fr)
      ); // max 3 when content-width is 840px
      list-style: none;
    }

    .caption {
      margin-top: calc(var(--baseline) * -1rem);
    }
  }

  // &-headingplus {

  // }

  &-gallery,
  &-image {
    figure {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // respect set aspect ratio
    // & always show full image
    img {
      // background-color: plum;
      height: 100%; // needed in case there is a caption
      object-fit: contain;
      width: 100%;
    }

    // or crop if needed
    [data-crop] img {
      // background-color: lightblue;
      object-fit: cover;
    }
  }

  &-linked_productions {
    ul {
      list-style: none;
      text-align: center;

      a {
        text-decoration: none;
      }
    }
  }

  &-manifesttags {
    @extend .blocktype-accordion;

    .accordion {
      label {
        font-size: var(--fontsize-large);
        text-align: center;
      }
    }

    .manifesttag {
      display: grid;
      gap: var(--whitespace);
      grid-template-columns: 75px 1fr;
      padding: calc(var(--whitespace) / 2) 0;
      
      @media screen and (min-width: 30rem) {
        grid-template-columns: 100px 1fr;
      }
    }

    svg {
      height: 100%;
      width: 100%;
    }

    span {
      word-break: break-word;
    }

    h3 {
      --baseline: 0.5;
      font-size: 1.25rem;
      line-height: 1;
      text-transform: none;
    }
  }

  &-synopsis {
    .booklet & .blocktype-accordion {
      margin: 0 auto;
    }
  }

  &-textplus {
    ol,
    ul {
      padding: 0 var(--whitespace);
    }
  }

  &-video {
    iframe {
      border: none;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
