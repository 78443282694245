// content carousel

// the image needs to fill up all available space while preserving ratio
.carousel img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

// option: https://medium.com/@marcusmichaels/how-to-build-a-carousel-from-scratch-in-vanilla-js-9a096d3b98c9
// https://codepen.io/marcusmichaels/pen/yGGoLM?editors=0100
// we'll be using the transform property to move the items,
// so setting preserve-3d will make sure our nested elements are rendered properly in the 3D space
.carousel-2 {
  height: 100%;
  overflow: hidden;
  transform-style: preserve-3d;

  // parent wrapper to carousel
  &-wrapper {
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: absolute; // needed when used in section as parallax background
    width: 100%;

    // give content position in 3D space so it shows above image
    // lessen width so navigation is reachable
    + .grid {
      width: calc(100% - 12rem);
      z-index: 0;
    }
  }

  // transition is taking the properties we wish to animate 'transform' and 'opacity', along with the length of time in seconds
  &-slide {
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: transform .5s, z-index .5s;
    width: 100%;
  }

  // bring initial or active item to the front using z-index
  &-slide.initial,
  &-slide.active {
    opacity: 1;
    position: relative;
    z-index: 20;
  }

  // set z-index to sit behind active item
  &-slide.prev,
  &-slide.next {
    z-index: 10;
  }

  // translate previous item to the left
  &-slide.prev {
    transform: translateX(-100%);
  }

  // translate next item to the right
  &-slide.next {
    transform: translateX(100%);
  }

  // navigation
  // style navigation buttons to sit in the middle, either side of the carousel
  &-pagination-prev,
  &-pagination-next {
    background-color: var(--color-white);
    border-radius: 50%;
    cursor: pointer;
    height: 1.5rem;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .5s;
    width: 1.5rem;
    z-index: 40;
    
    &:hover {
      border: 5px solid var(--color-white);
      opacity: 1 !important;
    }
  }

  &-pagination-prev {
    left: var(--gutter);
  }

  &-pagination-next {
    right: var(--gutter);
  }

  // use pseudo elements to insert arrows inside of navigation buttons
  // &-pagination-prev::after,
  // &-pagination-next::after {
  //   border-bottom: 2px solid black;
  //   border-right: 2px solid black;
  //   content: " ";
  //   height: 10px;
  //   left: 54%;
  //   position: absolute;
  //   top: 50%;
  //   transform: translate(-50%, -50%) rotate(135deg);
  //   width: 10px;
  // }

  // &-pagination-next::after {
  //   left: 47%;
  //   transform: translate(-50%, -50%) rotate(-45deg);
  // }
}

// option: multiple carousel instances but not loopable
// https://codepen.io/brianmarco/pen/WNdaNov
.carousel {
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  
  &-container {
    // height: 100%;
    // overflow: hidden;
    // position: relative;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }
  
  &-track {
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    transition: transform .5s ease-in-out;
  }
  
  &-slide {
    background-color: var(--color-black);
    bottom: 0;
    display: grid;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  .slide-info {
    align-self: end;
    background-color: transparent;
    margin: 0 auto 10vh;
    max-width: var(--content-width);
    padding: 1rem;
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

  .slide-title {
    font-size: 1.25rem;
    text-transform: uppercase;
    
    @media screen and (min-width: 48rem) {
      font-size: 1.75rem;
    }
  }

  // navigation
  // style navigation buttons to sit in the middle, either side of the carousel
  &-pagination-prev,
  &-pagination-next {
    background-color: var(--color-white);
    border-radius: 50%;
    cursor: pointer;
    height: 1.5rem;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .5s;
    width: 1.5rem;
    z-index: 40;
    
    &:hover {
      border: 5px solid var(--color-white);
      opacity: 1 !important;
    }
  }

  &-pagination-prev {
    left: var(--gutter);
  }

  &-pagination-next {
    right: var(--gutter);
  }

  .is-hidden {
    display: none;
  }

  // give content position in 3D space so it shows above image
  // lessen width so navigation is reachable
  .grid {
    height: max-content;
    min-width: 375px;
    padding: 15vh var(--whitespace) 0;
    width: calc(100% - 12rem);
    z-index: 1;
  }
}