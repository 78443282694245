// Typography

html {
  font-family: var(--fontfamily-sans);
}

body {
  color: var(--color-text);
  font-family: var(--fontfamily-bodycopy);
  font-size: var(--fontsize-bodycopy);
  line-height: var(--baseline);
  text-rendering: optimizeLegibility;
}

// https://blog.logrocket.com/guide-css-word-wrap-overflow-wrap-word-break/
h1, h2, h3 {
  font-family: var(--fontfamily-heading);
  font-weight: normal;
  hyphens: auto;
  letter-spacing: -0.025em;
  overflow-wrap: anywhere;
  text-transform: uppercase;

  // underline tag as hook for alternative font
  u {
    font-family: var(--fontfamily-bodycopy);
    text-decoration: none;
  }
}

h1 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1;
}

h2 {
  font-size: 2.25rem;
  line-height: 1.225;
}

h3  {
  font-size: 2rem;
  line-height: 1.225;
}

[class*='tagline'] {
  font-family: var(--fontfamily-tagline);
  font-size: 1.25rem;
}

.summary {
  font-family: var(--fontfamily-tagline);
}

@media screen and (min-width: 24rem) {
  h1 {
    font-size: 3rem;
  }
}

@media screen and (min-width: 48rem) {
  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 3rem;
  }

  [class*='tagline'] {
    font-size: 1.5rem;
  }
}

figcaption {
  font-size: var(--fontsize-small);
}

input {
  font-family: var(--fontfamily-ui);
  font-size: var(--fontsize-small);
}

// main {
//   hyphens: auto;
// }

main a,
footer a,
main button,
footer button {
  color: currentColor;
  transition: all .25s ease-in-out;
  
  &:hover {
    background-color: var(--color-text);
    color: var(--color-background);
    // padding: 0 0.15rem;
    text-decoration: none;
  }
}

footer {
  font-size: var(--fontsize-base);
  
  h3 {
    font-family: var(--fontfamily-bodycopy);
    font-size: 1.5rem;
  }
}



// // https://betterwebtype.com/articles/2018/10/15/rhythm-in-web-typography/

// // Variables
// $base-font-size: 112.5; // Gets used as %
// $line-height: 1.45;

// // Vertical rhythm mixins
// @mixin line-height($number) {
//   line-height: #{ $number * $line-height + 'rem'};
// }

// @mixin margin-top($number) {
//   margin-top: #{ $number * $line-height + 'rem'};
// }

// @mixin margin-bottom($number) {
//   margin-bottom: #{ $number * $line-height + 'rem'};
// }

// html {
//   font-size: #{$base-font-size + '%'}; // 112.5% = 18 pixels
// }

// * {
//   @include line-height(1);
//   @include margin-bottom(1);
//   @include margin-top(0);
// }
// // Headings parameters [ h1: line-height: 2 × 26px, margin-top: 3 × 26px, margin-bottom: 1 × 26px ]
// $headings: (
//   h1: (2, 3, 1),
//   h2: (1.5, 2, 1),
//   h3: (1.5, 1, 0),
//   h4: (1, 1, 0),
//   h5: (1, 1, 0),
//   h6: (1, 1, 0)
// );

// // Set line-heights and margins
// @each $heading, $properties in $headings {
//   #{$heading} {
//     @include line-height(nth($properties, 1));
//     @include margin-top(nth($properties, 2));
//     @include margin-bottom(nth($properties, 3));
//   }
// }