// Footer

body > footer {
  margin-top: var(--gutter);

  .branding {
    background: url(../images/logo-silbersee-black-line.svg) no-repeat 0px 0px;
    height: 24px;
    margin: 0 auto;
    max-width: calc(var(--content-width) - var(--gutter));
    width: calc(100%  - var(--gutter) / 2);
    
    svg {
      fill: #231F20;
    }
  }
  
  .social {
    margin: 0 auto;
    max-width: calc(var(--content-width) - var(--gutter));
    width: calc(100%  - var(--gutter) / 2);

    ul {
      display: flex;
      gap: var(--gutter);
      justify-content: center;
      list-style: none;
    }

    svg {
      fill: var(--color-text);
      height: 2rem;
      transition: all .25s ease-in-out;
      width: 2rem;
    }

    a:hover {
      background-color: transparent;

      svg {
        transform: scale(1.2);
      }
    }
  }
}

body.booklet > footer {

  .branding {
    background: url(../images/logo-silbersee-white-line.svg) no-repeat 0px 0px;
  }
}