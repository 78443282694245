// CSS picked up by all templates

// CSS Colour Palette

@use 'settings';
@use 'fonts';
@use 'typography';
@use 'layout';
@use 'header';
@use 'cover';
@use 'footer';
@use 'blocktypes';
@use 'carousel';
@use 'form';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

// page backgroundcolours
body {
  background-color: var(--color-background);
}

.id {
  // calendar
  &-sPZAnprGa36jrSYv {
    --color-background: var(--color-brand-3);
  }

  // creators
  &-fqGlrOvrGsXAix6g {
    --color-background: var(--color-brand-4);
  }

  // home
  &-4ctYj04XNw8TZ4XI {
    --color-background: var(--color-brand-1);
  }

  // kitchensink
  &-2HLJ6heYamBwlCYG {
    --color-background: var(--color-brand-3);
  }

  // manifest
  &-AAq7hIdqK1IEjFrV {
    --color-background: var(--color-brand-4);
  }

  // parliament
  &-FzaLNCJ4wj7rIvUI {
    --color-background: var(--color-brand-4);
  }

  // productions
  &-YDLYvueOhZwB3glM {
    --color-background: var(--color-brand-2);
  }

  // waterwende
  &-7hOJMzh9wh9lM2BC {
    --color-background: var(--color-brand-5);
  }
}

// parallax sections
main .parallax,
main .carousel {
  display: grid;
  min-height: 80vh;
  overflow: hidden;
  position: relative;

  // SimpleParallax element
  .simpleParallax {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

img {
  max-width: 100%;
}

figcaption {
  color: var(--color-text);
  padding: 0.5rem;
}

.btn {
  --height: 3rem;
  align-items: center;
  background-color: var(--color-text);
  border: 0;
  border-radius: 2rem;
  color: var(--color-background);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--fontfamily-bodycopy);
  font-size: inherit;
  height: var(--height);
  padding: 2px 1rem 0;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
}

.credits {
  text-align: center;

  strong + strong {
    &::before {
      content: '|';
      margin-right: 0.5ch;
    }
  }
}

// the divider between the sections
// option 1: fully automatic
// body:not([class*='booklet']) main section + section {
body:not([class*='booklet']) {
  main section + section:not(.carousel, .parallax) {
    > div {
      padding-top: 0;

      &::before {
        background-color: var(--color-text);
        content: '';
        display: inline-block;
        grid-column-end: -1;
        grid-column-start: 1;
        height: 2px;
      }
    }
  }

  main section.carousel + section,
  main section.parallax + section,
  main section.hide-divider + section {
    > div {
      padding-top: var(--gutter);

      &::before {
        display: none !important;
      }
    }
  }

  main section.hide-divider {
    .grid {
      padding-bottom: 0;
    }
  }
}

// option 2: manual using hr
hr {
  border: 1px solid var(--color-black);
}

// option 3: as placeholder
%sectiondivider {
  > div {
    padding-top: 0;

    &::before {
      background-color: var(--color-black);
      content: '';
      display: inline-block;
      grid-column-end: -1;
      grid-column-start: 1;
      height: 2px;
    }
  }
}

// the calendar list
@mixin playdates-fullwidth($date-width: 30%) {
  @media screen and (min-width: 60rem) {
    li {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      min-height: 4rem;
    }

    .playdate-info {
      flex-direction: row;

      span {
        width: 100%;
      }
    }

    .date {
      min-width: $date-width;
    }
  }
}

.playdates {
  font-family: var(--fontfamily-ui);
  font-size: var(--fontsize-base);
  list-style: none;
  scroll-margin-top: var(--gutter);
  text-transform: uppercase;

  // base layout
  li {
    align-items: center;
    container-name: playdate-listitem;
    container-type: inline-size;
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
  }

  .playdate-info {
    display: flex;
    flex-direction: column;
    gap: 0 0.5rem;
    width: 87.5%;
  }

  @container playdate-listitem (width > 80ch) {
    li {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      min-height: 4rem;
    }

    .playdate-info {
      align-items: center;
      flex-direction: row;

      span {
        width: 100%;
      }
    }
  }

  // specific styles on child elements
  .date {
    font-family: 'maison_monodemi', var(--fontfamily-ui);
  }

  .title a {
    text-decoration: none;
  }

  .btn {
    --height: 2rem;
  }

  // location specific styles

  // calendar page
  .id-sPZAnprGa36jrSYv & {
    @include playdates-fullwidth(25%);

    // show dividers
    li:not(:last-of-type) {
      border-bottom: 2px solid var(--color-black);
    }

    // include cover image
    img {
      aspect-ratio: 1/1;
      grid-area: image;
      object-fit: cover;
      width: 80px;
    }
  }

  // production page
  // .id-YDLYvueOhZwB3glM &
  .template-production & {
    @include playdates-fullwidth(42%);

    // show dividers
    li:not(:last-of-type) {
      border-bottom: 2px solid var(--color-black);
    }
  }

  // used in blocktype-calendar-preview
  .blocktype-calendar-preview & {
    text-transform: none;

    .playdate-info {
      align-items: center;
      gap: 0;
    }
  }
}

// the imagegrid list
.imagegrid {
  display: grid;
  gap: var(--gutter);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  list-style: none;

  .archive & {
    --color-text: var(--color-brand-2);
    --color-background: var(--color-black);
  }

  .profiles &,
  .castandcrew &,
  .stories & {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  a {
    text-decoration: none;
  }

  figure {
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
  }

  img {
    aspect-ratio: 2/1;
    object-fit: cover;
    position: relative;
    transition: all 2s ease-in-out;
    width: 100%;
    z-index: 0;

    .profiles &,
    .stories & {
      aspect-ratio: 1/1;
    }

    .castandcrew & {
      aspect-ratio: 3/2;
    }
  }

  figcaption {
    background-color: var(--color-background);
    margin-top: -10px;
    position: relative;
    text-transform: uppercase;
    transition: all 1s ease-in-out;
    z-index: 1;
  }

  .castandcrew & figcaption {
    p + p {
      text-transform: none;
    }
  }

  .stories & figcaption {
    font-size: var(--fontsize-bodycopy);
    line-height: 1.25;
  }

  li {
    position: relative;
  }

  li:hover {
    img {
      transform: scale(1.1);
      transition: all 1s ease-in-out;
    }

    figcaption {
      background-color: var(--color-text);
      color: var(--color-background);
      transition: all 0.25s ease-in-out;
    }

    .castandcrew & figcaption {
      background-color: var(--color-background);
      color: var(--color-text);
    }
  }

  // overviews can have figcaptions on multiple lines so we position the badge from the top
  .badge * {
    right: -0.5rem;
    top: 14rem;
  }
}

// horizontal scrollable sections
// using flex:
.scroll-flex {
  display: flex;
  flex-wrap: nowrap;
  max-width: 912px;
  overflow-x: scroll;

  li {
    flex: 0 0 auto;
    // display: inline-block;
    width: 325px;

    @media screen and (min-width: 26.75rem) {
      width: 80vw;
    }

    @media screen and (min-width: 30rem) {
      width: 60vw;
    }

    @media screen and (min-width: 30rem) {
      width: 60vw;
    }

    @media screen and (min-width: 45rem) {
      width: 40vw;
    }
    // extreme wide screens
    @media screen and (min-width: 60rem) {
      width: 400px;
    }
  }

  img {
    max-height: 500px;
  }
}

// using white-space:
.scroll {
  display: block;
  max-width: 912px;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  div {
    display: inline-block;
    width: 100%;
  }

  div + div {
    margin-left: var(--gutter);
  }
}

.scrollnotification {
  align-items: center;
  display: flex;
  font-size: var(--fontsize-small);
  gap: var(--whitespace);
  justify-content: space-between;
  padding: 0.5rem;

  .scrollicon {
    font-size: 2rem;
    line-height: 1;
    transform: translateY(-5px);
  }
}

.badge * {
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--fontsize-base);
  padding: 0.25rem 0.5rem;
  position: absolute;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.badge a:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}

.archive {
  --color-text: var(--color-white);
  --color-background: var(--color-black);
  background-color: var(--color-background);
  color: var(--color-text);
}

// the newsarticle list
.articles {
  ul {
    font-family: 'maison_monolight', var(--fontfamily-sans);
    font-size: var(--fontsize-base);
    list-style: none;
    text-transform: uppercase;
  }

  // layout
  li {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    &:not(:last-of-type) {
      border-bottom: 2px solid var(--color-black);
    }
  }

  @media screen and (min-width: 60rem) {
    li {
      align-items: flex-start;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;
      min-height: 4rem;
    }
  }

  // specific styles on child elements
  a {
    text-decoration: none;
  }

  .date {
    flex: 0 0 auto;
  }
}

.pagination {
  ul {
    display: flex;
    gap: 1rem;
    justify-content: center;
    list-style: none;
  }

  a {
    padding: 0 0.5rem;
    text-decoration: none;

    &[aria-current='page'],
    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
}

.ticketbar {
  font-size: var(--fontsize-base);

  @media screen and (min-width: 24rem) {
    font-size: var(--fontsize-bodycopy);
  }

  .content {
    align-items: center;
    border-bottom: 2px solid var(--color-black);
    display: flex;
    justify-content: space-between;
    padding: var(--whitespace);
  }

  .playdate-info {
    display: flex;
    flex-wrap: wrap;
    gap: 0 1rem;
  }

  .btn {
    --height: 2.5rem;
  }
}

// showreel temp styles
// respect set aspect ratio
.showreel {
  // background-image: url('/producties/SilberseeShowreel2019.png');
  // background-size: cover;

  // & always show full image
  iframe {
    // background-color: plum;
    border: none;
    height: 100%; // needed in case there is a caption
    object-fit: contain;
    width: 100%;
  }
  figcaption {
    text-transform: uppercase;
  }
}

// Cookiebot overrides
body > div:not(.topbar):first-child,
.CookieDeclaration {
  display: none;
}
